import React, { useEffect, useState } from 'react';
import {
  LineChartOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, DatePicker, Typography, Input } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, LabelList } from 'recharts';
import * as XLSX from "xlsx";
import "./MainPage.css";
import Logo from "../toyota.svg";
import dayjs from 'dayjs';

const { Sider, Content } = Layout;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const TYPE_MAP: any = {
  MAIN: "메인",
  CROWN: "크라운",
  CAMRY: "캠리",
  HIGHLANDER: "하이랜더",
  RAV4: "RAV4",
  ALPHARD: "알파드",
  SIENNA: "시애나",
  GR_SUPRA: "GR SUPRA",
  GR_86: "GR 86",
  CAMRYHYBRIDXSE: "CAMRYHYBRIDXSE",
  CAMRYHYBRIDXLE: "CAMRYHYBRIDXLE",
  CAMRYHYBRIDLE: "CAMRYHYBRIDLE",
  PRIUS: "프리우스",
  PRIUS_HEV: "프리우스HEV",
  RAV4HYBRID: "RAV4HYBRID",
  SIENNAHYBRID: "SIENNAHYBRID",
  TESTDRIVING: "시승신청",
}

const INIT_ARR = [
  {
    "name": "MAIN",
    "label": "메인",
    "uv": 0,
  },
  {
    "name": "CROWN",
    "label": "크라운",
    "uv": 0,
  },
  {
    "name": "CAMRY",
    "label": "캠리",
    "uv": 0,
  },
  {
    "name": "HIGHLANDER",
    "label": "하이랜더",
    "uv": 0,
  },
  {
    "name": "RAV4",
    "label": "RAV4",
    "uv": 0,
  },
  {
    "name": "ALPHARD",
    "label": "알파드",
    "uv": 0,
  },
  {
    "name": "SIENNA",
    "label": "시에나",
    "uv": 0,
  },
  {
    "name": "GR_SUPRA",
    "label": "GR 수프라",
    "uv": 0,
  },
  {
    "name": "GR_86",
    "label": "GR 86",
    "uv": 0,
  },
  {
    "name": "PRIUS",
    "label": "프리우스",
    "uv": 0,
  },
  {
    "name": "PRIUS_HEV",
    "label": "프리우스HEV",
    "uv": 0,
  },
  {
    "name": "TESTDRIVING",
    "label": "시승신청",
    "uv": 0,
  }
];

const INIT_DATA = {
  "MAIN": 0,
  "TESTDRIVING": 0,
  "CROWN": 0,
  "CAMRY": 0,
  "HIGHLANDER": 0,
  "RAV4": 0,
  "ALPHARD": 0,
  "SIENNA": 0,
  "GR_SUPRA": 0,
  "GR_86": 0,
  "PRIUS": 0,
  "PRIUS_HEV": 0,
}

const DATA_URL = "https://toyota-admin-default-rtdb.asia-southeast1.firebasedatabase.app/stat.json";
const ID = "freemon";
const PW = "4290";

const MainPage: React.FC = () => {
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");

  const [GRAPH_MODE, setGRAPH_MODE] = useState("ALL" as string); // "ALL" "ALL_START_ONLY" "DETAIL" "SELECT" "NONE"
  const [data, setData] = useState([...INIT_ARR]);
  const [rangeDate, setRangeDate] = useState<any[]>([]);
  const [detailDate, setDetailDate] = useState<string>("");
  const [car, setCar] = useState<string>("");
  const [isLogin, setIsLogin] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    formatData();
  }, [rangeDate]);

  const formatData = () => {
    let d: any = { ...INIT_DATA };
    const range = [...rangeDate];
    const start = range[0];
    const end = range[1];

    console.log(rangeDate);

    fetch(DATA_URL)
      .then((res) => res.json())
      .then((res) => {
        // object to array
        const arr = Object.values(res);
        let filteredArr: any[] = [...arr];

        if (range.length === 2) {
          filteredArr = [...filteredArr].filter((item: any) => {
            const date = new Date(item.time);
            const startDate = new Date(start);
            const endDate = new Date(end);
            return date >= startDate && date <= endDate;
          });
        }

        if (filteredArr.length >= 0) {
          filteredArr.map((item: any) => {
            d[item.type] += 1;
          });

          const result = DataObjectToArray(d);
          console.log("result : ", result);
          setData(result);
        } else {
          console.log("NO DATA");
        }
      })
      .catch(err => {
        alert("데이터를 가져오는데 문제가 발생하였습니다.");
      });

  }

  const DataObjectToArray = (obj: any): any[] => {
    const arr = Object.entries(obj).map((item) => {
      const [key, value] = item;
      return { name: key, uv: value, label: TYPE_MAP[key] };
    });
    return [...arr];
  }

  const handleOnRangeChange = (date: any, dateString: any) => {
    console.log(date, dateString);
    setRangeDate(dateString);
  };

  const handleXlsxExport = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "toyota_통계.xlsx");
  };

  const handleOnChangeId = (e: any) => {
    setInputId(e.target.value);
  };

  const handleOnChangePw = (e: any) => {
    setInputPw(e.target.value);
  };

  const handleLogin = () => {
    if (inputId === ID && inputPw === PW) {
      setIsLogin(true);
    } else {
      alert("아이디와 비밀번호를 확인해주세요.");
    }
  }

  const handleOnMenuSelect = (event: any) => {
    const { key } = event;

    setData([]);

    switch (key) {
      case "ALL":
        setGRAPH_MODE("ALL");
        formatData();
        break;
      case "ALL_START_ONLY":
        setGRAPH_MODE("ALL_START_ONLY");
        break;
      case "MAIN":
      case "TESTDRIVING":
      case "CROWN":
      case "CAMRY":
      case "HIGHLANDER":
      case "RAV4":
      case "ALPHARD":
      case "SIENNA":
      case "GR_SUPRA":
      case "GR_86":
      case "PRIUS":
      case "PRIUS_HEV":
        setGRAPH_MODE("SELECT");
        setCar(key);
        break;
      default:
        setGRAPH_MODE("NONE");
        break;
    }
  }

  const setRangeMonth = () => {
    //get this month range
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0, 23, 59, 59);

    setRangeDate([firstDay.toISOString(), lastDay.toISOString()]);
  }

  const setRangeWeek = () => {
    //get this week range
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();
    const day = today.getDay();
    const firstDay = new Date(year, month, date - day + 1);
    const lastDay = new Date(year, month, date + (8 - day));

    setRangeDate([firstDay.toISOString(), lastDay.toISOString()]);
  }

  const setRangeDay = () => {
    //get this day 0 -24h range
    const today = new Date();

    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

    setRangeDate([start.toISOString(), end.toISOString()]);
  }

  const handleOnSelectRangeChange = (date: any, dateString: any) => {
    setDetailDate(dateString);
  };

  const setDetailMonth = () => {
    getDetailData("MONTH");
  }

  const setDetailWeek = () => {
    getDetailData("WEEK");
  }

  const setDetailDay = () => {
    getDetailData("DAY");
  }

  const getDetailData = (mode: string) => {

    if (!detailDate) {
      alert("날짜를 선택해주세요.");
      return;
    }

    const start = new Date(detailDate);
    let end = new Date();

    if (mode === "MONTH") {
      end = new Date(start.getFullYear(), start.getMonth() + 1, 0, 23, 59, 59);      
    } else if (mode === "WEEK") {
      end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + (8 - start.getDay()), 23, 59, 59);
    } else if (mode === "DAY") {
      end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59, 59);
    }

    if (GRAPH_MODE === "ALL_START_ONLY") {
      let d: any = { ...INIT_DATA };

      fetch(DATA_URL)
      .then((res) => res.json())
      .then((res) => {
        // object to array
        const arr = Object.values(res);
        let filteredArr: any[] = [...arr];

        filteredArr = [...filteredArr].filter((item: any) => {
            const date = new Date(item.time);
            const startDate = new Date(start);
            const endDate = new Date(end);
            return date >= startDate && date <= endDate;
        });

        if (filteredArr.length >= 0) {
          filteredArr.map((item: any) => {
            d[item.type] += 1;
          });

          const result = DataObjectToArray(d);
          setData(result);
        } else {
          console.log("NO DATA");
        }
      })
      .catch(err => {
        alert("데이터를 가져오는데 문제가 발생하였습니다.");
      });

    } else {
      fetch(DATA_URL)
      .then((res) => res.json())
      .then((res) => {
        // object to array
        const arr = Object.values(res);

        let filteredArr: any[] = [...arr].filter((item: any) => {
          return item.type === car;
        });

        if (filteredArr.length >= 0) {
          filteredArr = [...filteredArr].filter((item: any) => {
            const date = new Date(item.time);
            return date >= start && date <= end;
          });
          // Arrangements organized by date
          let d: any = {};
          filteredArr.map((item: any) => {
            const date = new Date(item.time);
            const key = date.toISOString().split("T")[0];
            if (!d[key]) {
              d[key] = 1;
            } else {
              d[key] += 1;
            }
          });
          
          let result = Object.entries(d).map((item) => {
            const [key, value] = item;
            return { name: key, label: key, uv: Number(value) };
          });

          setData(result);

          console.log("result : ", result);

        } else {
          console.log("NO DATA");
        }
      })
      .catch(err => {
        alert("데이터를 가져오는데 문제가 발생하였습니다.");
      });
    }
  }

  //render flag

  if (!isLogin) {
    return (
      <Layout>
        <Layout>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <img className='logo' src={Logo} />
            <Title>토요타 샵앤샵 키오스크 관리자</Title>
            <Input placeholder="아이디" onChange={handleOnChangeId} />
            <br />
            <br />
            <Input.Password placeholder="비밀번호" onChange={handleOnChangePw} />
            <br />
            <br />
            <Button onClick={handleLogin}>로그인</Button>
          </Content>
        </Layout>
      </Layout>
    )
  }

  return (
    <Layout>
      <Sider trigger={null} style={{ height: '100vh' }}>
        <div className="title-text">토요타 샵앤샵 키오스크 관리자</div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: 'ALL',
              icon: <LineChartOutlined />,
              label: '통계',
            },
            {
              key: 'ALL_START_ONLY',
              icon: <LineChartOutlined />,
              label: '통계 월/주/일 간',
            },
            {
              key: 'MAIN',
              icon: <LineChartOutlined />,
              label: '메인',
            },
            {
              key: 'TESTDRIVING',
              icon: <LineChartOutlined />,
              label: '시승신청',
            },
            {
              key: 'ALPHARD',
              icon: <LineChartOutlined />,
              label: '알파드',
            },
            {
              key: 'HIGHLANDER',
              icon: <LineChartOutlined />,
              label: '하이랜더',
            },
            {
              key: 'CROWN',
              icon: <LineChartOutlined />,
              label: '크라운',
            },
            {
              key: 'RAV4',
              icon: <LineChartOutlined />,
              label: 'rav4',
            },
            {
              key: 'CAMRY',
              icon: <LineChartOutlined />,
              label: '캠리',
            },
            {
              key: 'SIENNA',
              icon: <LineChartOutlined />,
              label: '시애나',
            },
            // {
            //   key: 'PRIUS',
            //   icon: <LineChartOutlined />,
            //   label: '프리우스',
            // },
            {
              key: 'PRIUS_HEV',
              icon: <LineChartOutlined />,
              label: '프리우스HEV',
            },
            {
              key: 'GR_86',
              icon: <LineChartOutlined />,
              label: 'GR86',
            },
            {
              key: 'GR_SUPRA',
              icon: <LineChartOutlined />,
              label: 'GR SUPRA',
            }
          ]}
          onSelect={handleOnMenuSelect}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Title>통계</Title>
          {
            GRAPH_MODE === "ALL" && (
              <>
                <RangePicker onChange={handleOnRangeChange} showTime />
                <Button onClick={setRangeMonth}>월간</Button>
                <Button onClick={setRangeWeek}>주간</Button>
                <Button onClick={setRangeDay}>일간</Button>
                <BarChart width={1100} height={250} data={data} defaultShowTooltip style={{ margin: "0 auto" }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="label" />
                  <YAxis />
                  <Tooltip />
                  <Bar name="횟수" dataKey="uv" fill="#82ca9d">
                  <LabelList
                    dataKey="uv"
                    position="top"
                    fill="black"
                  />
                  </Bar>
                </BarChart>
              </>

            )
          }
          {
            GRAPH_MODE === "ALL_START_ONLY" && (
              <>
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                  onChange={handleOnSelectRangeChange}
                />
                <Button onClick={setRangeMonth}>월간</Button>
                <Button onClick={setRangeWeek}>주간</Button>
                <Button onClick={setRangeDay}>일간</Button>
                <BarChart width={1100} height={250} data={data} defaultShowTooltip style={{ margin: "0 auto" }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="label" />
                  <YAxis />
                  <Tooltip />
                  <Bar name="횟수" dataKey="uv" fill="#82ca9d">
                  <LabelList
                    dataKey="uv"
                    position="top"
                    fill="black"
                  />
                  </Bar>
                </BarChart>
              </>

            )
          }
          {
            GRAPH_MODE === "SELECT" && (
              <>
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                  onChange={handleOnSelectRangeChange}
                />
                <Button onClick={setDetailMonth}>월간</Button>
                <Button onClick={setDetailWeek}>주간</Button>
                <Button onClick={setDetailDay}>일간</Button>
                <LineChart data={data} width={1000} height={250} >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
              </>
            )
          }
          <Button onClick={handleXlsxExport}>XLSX로 내보내기</Button>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainPage;
