import React from 'react';
import { Button } from 'antd';
import './App.css';
import MainPage from './page/MainPage';

function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
